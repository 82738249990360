"use client";

import { SiFacebook, SiInstagram, SiLinkedin, SiX, SiYoutube } from "@icons-pack/react-simple-icons";
import Link from "next/link";
import { ReactNode, useEffect } from "react";
import { useIntl } from "react-intl";
import { graphql, PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { DarkModeToggleButton } from "@/components/dark-mode-toggle-button/dark-mode-toggle-button";
import { Logo } from "@/components/logo/logo";
import {
  LoadedProductHeaderUserSection,
  LoadingProductHeaderUserSection,
} from "@/components/product-header-user-section/product-header-user-section";
import { layoutProductQuery as LayoutProductQuery } from "@/relay/__generated__/layoutProductQuery.graphql";

const query = graphql`
  query layoutProductQuery {
    ...productHeaderUserSectionFragment
  }
`;

const ProductLayoutContent = (properties: { children: ReactNode; headerUserSection: ReactNode }) => {
  const { children, headerUserSection } = properties;
  const intl = useIntl();

  return (
    <div className="flex h-full flex-col">
      <header className="flex h-16 shrink-0 border-b border-transparent bg-white dark:bg-black">
        <nav className="mx-auto flex w-full max-w-7xl items-center justify-between px-6 lg:px-8">
          <div className="flex lg:flex-1">
            <Link
              className="relative -m-1.5 flex items-center rounded-md p-1.5 text-neutral-12"
              href="/"
              title={intl.formatMessage({
                defaultMessage: "Defense Futures Simulator",
                id: "KLcY/2",
              })}
            >
              <Logo className="h-12 w-auto text-primary-12" />
            </Link>
          </div>
          {headerUserSection}
        </nav>
      </header>
      <div className="flex-1">{children}</div>
      <footer className="bg-white dark:bg-black">
        <div className="mx-auto max-w-7xl px-6 pb-6 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <Link
                className="relative -ms-1 inline-block rounded-md p-1 focus-visible:outline-primary-dark-7"
                href="/"
              >
                <Logo
                  aria-label={intl.formatMessage({ defaultMessage: "DFS", id: "vYwf3w" })}
                  className="h-12 w-auto text-primary-dark-11"
                />
              </Link>
              <p className="text-sm leading-6 text-neutral-11">
                {intl.formatMessage({
                  defaultMessage: "The Defense Futures Simulator elevates defense analysis.",
                  id: "0bwdXh",
                })}
              </p>
              <div className="-ms-0.5 flex space-x-6">
                <Link
                  className="rounded-md p-0.5 text-primary-dark-11 hover:text-primary-dark-12 focus-visible:outline-primary-dark-7"
                  href="https://www.facebook.com/aei"
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: "Facebook",
                      id: "EmpHyB",
                    })}
                  </span>
                  <SiFacebook aria-hidden className="size-5" />
                </Link>
                <Link
                  className="rounded-md p-0.5 text-primary-dark-11 hover:text-primary-dark-12 focus-visible:outline-primary-dark-7"
                  href="http://instagram.com/aei"
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: "Instagram",
                      id: "39PtLD",
                    })}
                  </span>
                  <SiInstagram aria-hidden className="size-5" />
                </Link>
                <Link
                  className="rounded-md p-0.5 text-primary-dark-11 hover:text-primary-dark-12 focus-visible:outline-primary-dark-7"
                  href="https://x.com/aei"
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: "X",
                      id: "MXPwVk",
                    })}
                  </span>
                  <SiX aria-hidden className="size-5" />
                </Link>
                <Link
                  className="rounded-md p-0.5 text-primary-dark-11 hover:text-primary-dark-12 focus-visible:outline-primary-dark-7"
                  href="https://www.linkedin.com/company/american-enterprise-institute"
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: "LinkedIn",
                      id: "Rb/hb9",
                    })}
                  </span>
                  <SiLinkedin aria-hidden className="size-5" />
                </Link>
                <Link
                  className="rounded-md p-0.5 text-primary-dark-11 hover:text-primary-dark-12 focus-visible:outline-primary-dark-7"
                  href="http://www.youtube.com/user/AEIVideos"
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: "YouTube",
                      id: "zuA3Bg",
                    })}
                  </span>
                  <SiYoutube aria-hidden className="size-5" />
                </Link>
                <Link
                  className="rounded-md text-sm leading-6 text-primary-11 hover:text-neutral-dark-12 focus-visible:outline-primary-dark-7"
                  href="https://www.aei.org/about/contact"
                  rel="noopener"
                  target="_blank"
                >
                  {intl.formatMessage({
                    defaultMessage: "Contact Us",
                    id: "hZOGLS",
                  })}
                </Link>
              </div>
            </div>
            <div className="mt-16 grid grid-cols-1 gap-8 md:grid-cols-2 xl:col-span-2 xl:mt-0">
              <div className="grid md:gap-8 xl:col-start-3">
                <div className="">
                  <h3 className="text-sm font-semibold leading-6 text-neutral-12">
                    {intl.formatMessage({
                      defaultMessage: "Legal",
                      id: "7oFrM6",
                    })}
                  </h3>
                  <ul className="mt-4 space-y-2">
                    <li>
                      <Link
                        className="rounded-md text-sm leading-6 text-primary-11 hover:text-neutral-dark-12 focus-visible:outline-primary-dark-7"
                        href="https://www.aei.org/about/terms-of-use/"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Privacy Policy and Terms of Use",
                          id: "h30cMo",
                        })}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-16 flex items-center border-t border-neutral-dark-6 pt-6 sm:mt-20 lg:mt-24">
            <p className="text-xs leading-5 text-neutral-11">
              <span className="block lg:inline">
                {intl.formatMessage({
                  defaultMessage: "© American Enterprise Institute. All rights reserved.",
                  id: "rEswe9",
                })}
              </span>
            </p>
            <div className="-me-2 ms-auto">
              <DarkModeToggleButton isDark />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export const LoadingProductLayout = (properties: { children: ReactNode }) => {
  const { children } = properties;
  const headerUserSection = <LoadingProductHeaderUserSection />;
  return <ProductLayoutContent headerUserSection={headerUserSection}>{children}</ProductLayoutContent>;
};

export const LoadedProductLayout = (properties: {
  children: ReactNode;
  preloadedQueryReference: PreloadedQuery<LayoutProductQuery>;
}) => {
  const { children, preloadedQueryReference } = properties;
  const queryReference = usePreloadedQuery<LayoutProductQuery>(query, preloadedQueryReference);
  const headerUserSection = <LoadedProductHeaderUserSection queryReference={queryReference} />;
  return <ProductLayoutContent headerUserSection={headerUserSection}>{children}</ProductLayoutContent>;
};

export const ProductLayout = (properties: { children: ReactNode }) => {
  const { children } = properties;
  const [preloadedQueryReference, loadQuery] = useQueryLoader<LayoutProductQuery>(query);

  useEffect(() => {
    loadQuery({});
  }, [loadQuery]);

  return preloadedQueryReference == null ? (
    <LoadingProductLayout>{children}</LoadingProductLayout>
  ) : (
    <LoadedProductLayout preloadedQueryReference={preloadedQueryReference}>{children}</LoadedProductLayout>
  );
};

export default ProductLayout;
